import {
  AddressAutofillOptions,
  AddressAutofillCore,
  AddressAutofillSuggestionResponse,
  AddressAutofillRetrieveResponse
} from './autofill/AddressAutofillCore';
import {
  AddressAutofillSuggestion,
  AddressAutofillFeatureSuggestion
} from './autofill/types';

import {
  SearchBoxOptions,
  SearchBoxCore,
  SearchBoxSuggestionResponse,
  SearchBoxRetrieveResponse,
  SearchBoxCategoryResponse,
  SearchBoxReverseResponse
} from './searchbox/SearchBoxCore';
import {
  SearchBoxAdministrativeUnitTypes,
  SearchBoxSuggestion,
  SearchBoxFeatureSuggestion,
  SearchBoxCategorySuggestion
} from './searchbox/types';

import {
  ValidationOptions,
  ValidationCore,
  ValidationResponse
} from './validate/ValidationCore';
import { ValidationFeature } from './validate/types';

import {
  GeocodingOptions,
  GeocodingCore,
  GeocodingResponse
} from './geocode/GeocodingCore';
import {
  GeocodingFeatureProperties,
  GeocodingFeatureContext,
  GeocodingFeature,
  DataTypes
} from './geocode/types';

import { MatchCodeConfidence, MatchCode } from './types';

import { SearchSession } from './SearchSession';
import { SessionToken, SessionTokenLike } from './SessionToken';
import { MapboxError } from './MapboxError';

import { LngLat, LngLatLike } from './LngLat';
import { LngLatBounds, LngLatBoundsLike } from './LngLatBounds';

import { polyfillFetch } from './fetch';
import { featureToSuggestion } from './featureToSuggestion';

import { Evented } from './utils/Evented';
import { debounce } from './utils/debounce';

export {
  SearchBoxOptions,
  SearchBoxCore,
  SearchBoxSuggestionResponse,
  SearchBoxRetrieveResponse,
  SearchBoxAdministrativeUnitTypes,
  SearchBoxSuggestion,
  SearchBoxFeatureSuggestion,
  SearchBoxCategoryResponse,
  SearchBoxReverseResponse,
  SearchBoxCategorySuggestion,
  AddressAutofillOptions,
  AddressAutofillCore,
  AddressAutofillSuggestionResponse,
  AddressAutofillRetrieveResponse,
  AddressAutofillSuggestion,
  AddressAutofillFeatureSuggestion,
  MatchCode,
  MatchCodeConfidence,
  SearchSession,
  SessionToken,
  SessionTokenLike,
  MapboxError,
  LngLat,
  LngLatLike,
  LngLatBounds,
  LngLatBoundsLike,
  polyfillFetch,
  featureToSuggestion,
  Evented,
  debounce,
  ValidationOptions,
  ValidationCore,
  ValidationResponse,
  ValidationFeature,
  GeocodingOptions,
  GeocodingCore,
  GeocodingResponse,
  GeocodingFeatureProperties,
  GeocodingFeatureContext,
  GeocodingFeature,
  DataTypes,
  // ALIASES FOR BACKWARD COMPATIBILITY
  AddressAutofillOptions as AutofillOptions,
  AddressAutofillCore as MapboxAutofill,
  AddressAutofillSuggestionResponse as AutofillSuggestionResponse,
  AddressAutofillRetrieveResponse as AutofillRetrieveResponse,
  AddressAutofillSuggestion as AutofillSuggestion,
  AddressAutofillFeatureSuggestion as AutofillFeatureSuggestion,
  ValidationOptions as ValidateOptions,
  ValidationCore as MapboxValidate,
  ValidationResponse as ValidateResponse,
  ValidationFeature as ValidateFeature,
  GeocodingOptions as GeocodeOptions,
  GeocodingCore as MapboxGeocode,
  GeocodingResponse as GeocodeResponse,
  GeocodingFeatureProperties as GeocodeFeatureProperties,
  GeocodingFeatureContext as GeocodeFeatureContext,
  GeocodingFeature as GeocodeFeature
};
